// @ts-ignore
import withAuth from "components/auth/withAuth";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetShowState, toggleSidebar } from "redux/show/showSlice";
import {
  useGetAllProductsQuery,
  useGetShopInfoQuery,
  useGetStoreAnalyticsQuery,
  useUserCheckQuery,
} from "services/baseApiSlice";

import Seo from "../../components/common/seo";
import App from "../../components/layout/app/index";
import Link from "next/link";

function HomePage() {
  const dispatch = useDispatch();
  const router = useRouter();

  const { shopId } = router.query;

  const token = useSelector((state: any) => state.auth.token);
  const { isError, error: errorUser } = useUserCheckQuery(token, {
    skip: !token,
    refetchOnMountOrArgChange: true,
  });

  const { error } = useGetShopInfoQuery(shopId, {
    skip: !shopId,
  });

  const userProfile = useSelector((state: any) => state.auth.userProfile);
  const businessProfile = useSelector(
    (state: any) => state.auth.businessProfile
  );


  useEffect(() => {
    dispatch(resetShowState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!userProfile?._id) {
      router.push("/");
      return;
    }
    // later on, a user may have multiple stores. Selected store ID will be saved on app store. For now we just get the first store
    if (userProfile?.stores?.length === 0) {
      router.push("/");
      return;
    }
    if (!businessProfile?._id) {
      router.push("/");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile, businessProfile]);

  // const [sideOpen, setSideOpen] = useState(true);
  const openedSidebar = useSelector((state: any) => state.show.openedSidebar);

  const getGreeting = () => {
    // possible greetings: morning, afternoon, evening. Suffix: , <name>
    const date = new Date();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const time = hours + minutes / 60 + seconds / 3600;
    if (time >= 0 && time < 12) {
      return "Good Morning";
    } else if (time >= 12 && time < 18) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  };

  const storeId = shopId;
  const { data: storeAnalytics, isFetching: gettingStats } =
    useGetStoreAnalyticsQuery(storeId, {
      skip: !storeId,
    });

  const [showsWorth, setShowsWorth] = useState(0);

  const page = 1;
  const { data: products } = useGetAllProductsQuery(
    {
      page,
      shopId,
    },
    {
      skip: !shopId,
    }
  );

  useEffect(() => {
    if (products?.products && storeAnalytics?.data) {
      let sum = 0;
      products?.products?.forEach((product: any) => {
        sum += parseFloat(product.price.$numberDecimal);
      });

      setShowsWorth(sum * (storeAnalytics?.data?.totalViews ?? 0));
    }
  }, [storeAnalytics?.data, products?.products]);

  const secondsToMins = (seconds: number) => {
    // output format: 1min 30sec
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;

    return `${mins}m ${secs}s`;
  };

  return (
    <>
      <Seo />
      <App
        activeIndex={0}
        setSearchResults={() => null}
        setSearchValue={() => null}
        data={[]}
        oldHeaderOnMobile
        hideTopNav
        setSideOpen={(val: boolean) => {
          dispatch(toggleSidebar(val));
        }}
      >
        <div
          className={`mt-[150px] flex relative h-full flex-wrap items-center mb-[100px] justify-start gap-3 transition-all duration-300 ease-in md:h-max ${
            openedSidebar
              ? "md:ml-[270px] md:w-[calc(100%_-_280px)]"
              : "md:ml-[60px] md:w-[calc(100%_-_60px)]"
          }`}
        >
          <h1 className="text-gray-800 font-bold absolute -top-12 md:left-0 left-4 text-2xl">
            {getGreeting()}, {userProfile?.firstName}
          </h1>
          {!gettingStats && storeAnalytics && (
            <div className="flex-wrap mb-8 items-center mt-8 flex justify-start gap-3 relative w-full">
              <StatTabNew
                bgColor="bg-[#6A00DE]"
                text="User Views"
                description="Monitor your views in the last 30 days. Gain insights into your audience demographics and location for strategic analysis."
                total={storeAnalytics?.data?.totalViews ?? 0}
              />
              <StatTabNew
                bgColor="bg-[#1F1D1F]"
                text="Offsite Viewing Time"
                description="Track your onsite viewing time for the past 30 days. Understand viewer behavior and average onsite show duration."
                total={secondsToMins(
                  storeAnalytics?.data?.totalTimePlayedOffsite ?? 0
                )}
              />
              <StatTabNew
                bgColor="bg-[#6A00DE]"
                text="Onsite Viewing Time"
                description="Track your onsite viewing time for the past 30 days. Understand viewer behavior and average onsite show duration."
                total={secondsToMins(
                  storeAnalytics?.data?.totalTimePlayedOnsite ?? 0
                )}
              />
              <StatTabNew
                bgColor="bg-[#1F1D1F]"
                text="Total Shows"
                description="Track your total shows over the past 30 days. Gain insights and analyze your content performance to make informed decisions."
                total={storeAnalytics?.data?.totalShows ?? 0}
              />
              <Link
                className="text-sm font-semibold text-[#1F1D1F] group absolute -bottom-8 right-8 flex items-center"
                href={`/${router.asPath}analytics`}
              >
                See Full Report
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-chevron-right group-hover:translate-x-1 transition-all ease-in duration-300"
                >
                  <path d="m9 18 6-6-6-6" />
                </svg>
              </Link>
            </div>
          )}

          {gettingStats && (
            <div className="flex-wrap items-center flex justify-start gap-3 w-full mt-4">
              <ShimmerStatTab />
              <ShimmerStatTab />
              <ShimmerStatTab />
              <ShimmerStatTab />
            </div>
          )}
        </div>
      </App>
    </>
  );
}

export default withAuth(HomePage);

function StatTabNew({
  bgColor,
  text,
  total,
  description,
  to,
}: {
  bgColor: string;
  text: string;
  total: string | number;
  description: string;
  to?: string;
}) {
  function formatNumber(num: number) {
    if (typeof num !== "number") {
      throw new Error("Input must be a number");
    }

    if (num >= 1e9) {
      return (num / 1e9).toFixed(1) + "b";
    }

    if (num >= 1e6) {
      return (num / 1e6).toFixed(1) + "m";
    }

    if (num >= 1e3) {
      return (num / 1e3).toFixed(1) + "k";
    }

    return num.toString();
  }

  return (
    <div
      className={`${bgColor} group cursor-pointer flex 2xl:h-[375px] h-[300px] min-w-[165px] relative flex-col items-start justify-end gap-4 rounded-2xl px-4 text-center lg:w-[calc((100%/3)_-_20px)] xl:w-[calc((100%/4)_-_20px)] 2xl:w-[calc((100%/4)_-_20px)] w-[95%] md:mx-0 mx-auto`}
    >
      <span className="absolute top-4 right-4 text-sm font-medium text-white-50">
        Last 30 Days
      </span>
      <div className="flex flex-col group-hover:h-[200px] transition-all origin-bottom ease-in duration-300 h-[80px] overflow-hidden gap-0 mb-4 w-full text-left">
        <h2 className="text-lg font-medium text-white-50">{text}</h2>
        <p className="text-4xl font-bold text-white-50">
          {typeof total === "number" ? formatNumber(total) : total}
        </p>
        <p className="opacity-0 group-hover:opacity-100 leading-[140%] self-end transition-all ease-in duration-300 pt-4 mt-auto text-base font-medium text-white-50">
          {description}
        </p>
      </div>
    </div>
  );
}

function ShimmerStatTab() {
  return (
    <div className="shimmer-wrapper-card flex 2xl:h-[375px] h-[300px] lg:w-[calc((100%/3)_-_20px)] xl:w-[calc((100%/4)_-_20px)] 2xl:w-[calc((100%/4)_-_20px)] w-[95%] md:mx-0 mx-auto min-w-[165px] flex-col items-center justify-center gap-4 rounded-2xl px-4 text-center">
      <div className="shimmer-wrapper-small h-[50px] w-[100px] text-4xl font-bold text-black"></div>
      <div className="shimmer-wrapper-small mx-auto h-[20px] w-4/5 text-xl font-medium text-black"></div>
      <div className="shimmer-wrapper-small mx-auto h-[10px] w-1/3 text-xs font-medium text-[#686868]"></div>
    </div>
  );
}
